import { PoNVoid } from '@/types'
import { cls } from '@/utils'
import { ReactElement, useCallback } from 'react'

export interface InfiniteProps<T = any> {
  dataSource: T[] | null
  className?: string
  loading?: boolean
  loadMore: () => PoNVoid
  hasMore?: boolean
  itemRenderer: (item: T, index: number, onShow: () => void) => ReactElement
  rowKey?: string
  cacheMargin?: number
}

export default function Infinite<T = any>({
  className,
  itemRenderer,
  dataSource,
  loading,
  loadMore,
  cacheMargin = 5,
}: InfiniteProps<T>) {
  const checkLoadMore = useCallback(
    (data: T) => {
      const index = (dataSource ?? []).findIndex((item) => item === data)
      const shouldLoadMore = index >= (dataSource ?? []).length - cacheMargin
      if (shouldLoadMore) {
        loadMore()
      }
    },
    [loadMore, dataSource, cacheMargin],
  )

  return (
    <div className={cls('relative flex flex-col size-full overflow-y-auto no-scrollbar', className)}>
      {dataSource?.map((item, index) => {
        return itemRenderer(item, index, () => checkLoadMore(item))
      })}
    </div>
  )
}
