'use client'

import { useEffect, useMemo } from 'react'
import { useSearchParams } from 'next/navigation'
import useAmplitude from '@/hooks/useAmplitude'
import { cls, whisper } from '@/utils'
import CreditUpgradeButton from '@/components/credit-upgrade-button'
import { useCachedTemplateCategories } from '@/hooks/useTemplateCategories'
import SocialLinks from '@/components/social-links'
import { useInfiniteTemplates } from '@/hooks/useTemplates'
import Infinite from '@/components/infinite'
import TemplateCard from '@/components/templates/card'
import TemplateIcon from '@/components/creation-input/creation-mode/icon'

export default function TemplatesPage() {
  const { data: categories } = useCachedTemplateCategories()

  const query = useSearchParams()
  const category_id = query.get('category') as string
  const category = useMemo(() => {
    return categories?.find((item) => item.category === category_id) ?? categories?.[0] ?? null
  }, [category_id, categories])

  whisper('category is: ', category)
  const { data: templatesRes, hasMore, loadMore } = useInfiniteTemplates({ category: category?.category })
  const templates = useMemo(() => {
    return templatesRes?.records ?? []
  }, [templatesRes])

  const { track } = useAmplitude()

  useEffect(() => {
    track('view:templates')
  }, [track])

  return (
    <div className={cls('size-full overflow-y-auto no-scrollbar pb-12')} data-testid='templates-page'>
      <div className='flex flex-col gap-8 p-4 md:px-16' aria-label='template category'>
        <div className='mb-4 md:mb-8 flex justify-between items-center gap-4'>
          <div className='text-heading-4xl font-moulin text-text font-bold hidden md:flex' aria-label='Templates'>
            Templates
          </div>
          <div className='flex gap-4 md:gap-8 items-center'>
            <SocialLinks />
            <CreditUpgradeButton showMembership className='md:h-10 px-2' source='templates' />
          </div>
        </div>
        <div className='flex flex-col gap-2 items-center' aria-label='template info'>
          <TemplateIcon src={category?.icon} size='lg' />
          <span className='text-heading-5xl leading-10 font-bold'>{category?.label}</span>
        </div>
        <div className='w-full' aria-label='templates'>
          <Infinite
            className='grid grid-cols-2 md:grid-cols-3 desktop:grid-cols-4 gap-4'
            dataSource={templates}
            hasMore={hasMore}
            loadMore={loadMore}
            itemRenderer={(template, index, onShow) => {
              return (
                <TemplateCard
                  key={template.template_id}
                  className='md:border-2 md:border-b-4 border-border'
                  data={template}
                />
              )
            }}
          />
        </div>
      </div>
    </div>
  )
}
