// import { redirect } from 'next/navigation'
'use client'

import Explore from '@/app/explore/page'
import { USECASE_ONLY } from '@/constants'
import TemplatesPage from './templates/page'

export default function Home() {
  // redirect('/explore')
  if (USECASE_ONLY) {
    return <TemplatesPage />
  }
  return <Explore />
}
